import { useRootLoaderData } from '@storefront/ui-components/hooks/useRootLoaderData';

export const useRegion = () => {
  const data = useRootLoaderData();

  const region = data?.regions.filter((region) => region.currency_code === 'cad')[0];

  if (!region) {
    throw new Error('No region found');
  }

  return { region };
};
